body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ----------------------------------- */

.number {
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  left: -18px;
  opacity: 0.2;
  font-family: "Open Sans Condensed", sans-serif;
  line-height: 1;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  font-weight: 400;
  background-color: #14171c;
  color: #dee2e7;
}

h3 {
  font-weight: 900;
  color: #fcfdff;
}

p {
  margin-top: 20px;
}

.social-icon {
  color: #ffffff;
}

.social-icon:hover {
  text-decoration: none;
}

.social-icon i {
  cursor: pointer;
  transition: all 100ms ease-out;
  display: flex;
}

.social-icon:hover i {
  color: #03a9f4 !important;
}

.social-icon:visited i {
  color: white;
}

a {
  font-weight: 700;
  color: #03a9f4;
}

.tile {
  height: 100px;
  width: 100px;
  display: inline-block;
  background-color: #ccc;
}

/* ----------------------------------- */

.clock {
  border-radius: 100%;
  border: 1px solid rgba(51, 51, 51, 0.1);
  overflow: hidden;
  background-color: #fff;
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.fulcrum {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #1a1a1a;
  transform: translate(-50%, -50%);
}

.center {
  position: relative;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
}

.center > * {
  position: absolute;
}

.hours .value {
  font-size: 1rem;
  z-index: 999;
  font-weight: 900;
}

.seconds .value {
  font-size: 0.75rem;
}

.tick .slice {
  position: absolute;
  transform-origin: 0 0;
}

.tick .slice .bg {
  width: 0;
  height: 0;
  border: 0 solid transparent;
}

.tick .slice .bg.light {
  border-right-color: #eee;
}

.tick .slice .bg.dark {
  border-right-color: #ccc;
}

.tick .line {
  width: 200px;
  position: absolute;
  transform-origin: 0% 0%;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.1);
}

.tick .line .label {
  color: fade(#333, 50%);
  text-align: right;
  transform: translateY(-50%);
  font-size: 0.75rem;
}

.tick .line .label.flip {
  text-align: left;
  transform: translateY(-50%) scale(-1, -1);
}

.tick .value {
  display: inline-block;
  position: absolute;
  transform: translate(-50%, -50%);
  color: black;
}

/* ----------------------------------- */

.marker {
  transform-origin: 0% 0%;
  border-top: 1px solid #f44336;
  text-align: right;
  padding-right: 70px;
  color: #f44336;
  font-size: 0.75rem;
}

.marker .labels {
  transform: translateY(-100%);
  font-size: 0.75rem;
}

.marker .labels.flip > * {
  transform: scale(-1, -1);
  text-align: left;
}

/* ----------------------------------- */

.ring {
  transform: translate(-50%, -50%);
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-radius: 100%;
}

/* ----------------------------------- */

.hand {
  background-color: #333;
  color: white;
  padding: 0 10px;
  border: 1px solid #1a1a1a;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 700;
}

.hand.flip > * {
  transform: scale(-1, -1);
}

.hand.ticker {
  background-color: #f44336;
  border-color: #ba160a;
}

.hand.accent {
  background-color: #03a9f4;
  border-color: #0398db;
}

.hand .tertiary {
  font-weight: 300;
  margin-right: 8px;
  color: fade(white, 80%);
  font-size: 0.6rem;
  line-height: 0.6rem;
}
